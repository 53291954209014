
import { ref, onMounted, reactive, defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import HorizontalStepper from '@/views/components/stepper/Horizontal.vue'
import MutationInfoForm from './components/MutationInfoForm.vue'
import SelectNewTrayekMutationForm from './components/SelectNewTrayekMutationForm.vue'
import MutationFormReview from './components/MutationReview.vue'

export default defineComponent({
  name: 'VehicleTable',
  components: {
    MutationInfoForm,
    SelectNewTrayekMutationForm,
    HorizontalStepper,
    MutationFormReview
  },
  setup() {
    const store = useStore()
    const formPosition = ref(1)

    const setformPosition = (val: any) => {
      formPosition.value = val
    }

    const hideSidebar = () => {
      store.dispatch('setShowModal', false)
    }

    const onSubmit = (data: any) => {
      console.log('formPosition', formPosition.value)
      if (formPosition.value === 0) {
        formPosition.value++
        console.log('formPosition', formPosition.value)
      } else {
        console.log('xxx')
      }
      console.log('dataSubmit', data)
    }

    const steps = ref([{
      key: 1,
      titleCircle: '1',
      title: 'Informasi Bus',
      iconDone: ''
    },
    {
      key: 2,
      titleCircle: '2',
      title: 'Pilih Bus Mutasi',
      iconDone: ''
    },
    {
      key: 3,
      titleCircle: '3',
      title: 'Review',
      iconDone: ''
    }])

    // const selectedStep = ref(2)
    const selectedStep = computed({
      get: () => store.state.vehicleMutation.formStep,
      set: (value) => {
        store.dispatch('setVehicleMutationFormStep', value)
      }
    })

    onMounted(() => {
      const titleForm = 'Mutasi'
      store.dispatch('flushVehicleMutationForm')
      store.dispatch('setTitleFormSidebar', `${titleForm} Kendaraan`)
      store.dispatch('setToggleDatatableSidebarDeleteButton', false)
    })

    return {
      hideSidebar,
      formPosition,
      setformPosition,
      onSubmit,
      selectedStep,
      steps
    }
  }
})
