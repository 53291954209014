
import { ref, onMounted, reactive, defineComponent } from 'vue'
import { useStore } from 'vuex'

import Button from 'primevue/button'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import apiUseCase from '@/usecase/apiUseCase'

export default defineComponent({
  name: 'VehicleTable',
  components: {
    Button,
    SelectFetch,
    Form,
    Field
  },
  setup() {
    const store = useStore()

    const isLoading = ref(false)
    const selectedBus = ref(null) as any
    const selectedBusDetail = ref(null) as any
    const endpointVehicle = '/management/v1/MsVehicle'

    const initialValue = reactive({
      SecondBus: store.state.vehicleMutation.secondBus ?? null
    })

    const schema = Yup.object().shape({
      SecondBus: Yup.object().required('Bus tidak boleh kosong'),
    })

    const dataForm = ref({
      nopol: 'L123123US'
    })

    const hideSidebar = () => {
      store.dispatch('setShowModal', false)
    }

    const prevPage = () => {
      store.dispatch('setVehicleMutationFormStep', 1)
    }

    const nextPage = (val: any) => {
      store.dispatch('setSecondVehicleMutation', selectedBusDetail.value)
      store.dispatch('setVehicleMutationFormStep', 3)
    }

    const getBusDetail = () => {
      store.dispatch('showLoading')
      isLoading.value = true
      apiUseCase.get(`${endpointVehicle}/${selectedBus.value.Id}`)
        .then(async ({ result }) => {
          // store.dispatch('storeRowDataTable', result)
          selectedBusDetail.value = {
            ...result,
            label: result.Nopol,
            value: result.Id
          }
          store.dispatch('hideLoading')
          isLoading.value = false
        })
        .catch(() => {
          store.dispatch('hideLoading')
          isLoading.value = false
        })
    }

    const setCurrVehicleInfo = (val: any) => {
      console.log(val)
      selectedBus.value = val
      if (selectedBus.value) {
        getBusDetail()
      } else {
        selectedBusDetail.value = null
      }
    }

    onMounted(() => {
      if (store.state.vehicleMutation.secondBus) {
        setCurrVehicleInfo(store.state.vehicleMutation.secondBus)
      }
    })

    return {
      isLoading,
      dataForm,
      hideSidebar,
      nextPage,
      schema,
      initialValue,
      setCurrVehicleInfo,
      selectedBusDetail,
      prevPage
    }
  }
})
