
import { defineComponent, getCurrentInstance, onMounted, ref, reactive, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Button from 'primevue/button'
import Card from 'primevue/card'
import OverlayPanel from 'primevue/overlaypanel'
import apiUseCase from '@/usecase/apiUseCase'
import vSelect from 'vue-select'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import SelectAsync from '@/views/components/form/SelectAsync.vue'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import DataEmpty from '../components/DataEmpty.vue'
import DataEmptySearch from '../components/DataEmptySearch.vue'

const TrayekList = defineAsyncComponent(() => import('../components/TrayekList.vue'))

const endpoint = '/management/v1/Trayek'
const endpointClass = '/management/v1/MsClass'

export default defineComponent({
  name: 'TrayekForm',
  components: {
    Button,
    Card,
    // Dropdown,
    InputIconRight,
    DataEmpty,
    DataEmptySearch,
    TrayekList,
    OverlayPanel,
    SelectAsync
    // RadioButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const app = getCurrentInstance()
    const {
      $icon
    } = app!.appContext.config.globalProperties
    const icon = $icon

    const inputSearch = ref(null)
    const inputSearchClass = ref('')
    const isSearch = ref(false)
    const pageState = ref(3)
    const isAnyTrayek = ref(true)
    const refOverlayAction = ref()
    const selectedClass = ref(null) as any
    const classOptions = ref([])
    const isTolFilter = ref(null)
    const isLoading = ref(true)
    const path = ref('master-trayek-tourist')
    const filters = reactive({
      search: '',
      class: null,
      isTol: null
    })

    const onSearch = () => {
      isSearch.value = true
      isAnyTrayek.value = true
      store.dispatch('setTrayekHeadSearchFilter', inputSearch.value)
      // store.dispatch('setSearchField', inputSearch.value)
      store.dispatch('setReloadTrayek', true)
    }

    const openOverlayPanel = (evt: any) => {
      refOverlayAction.value.toggle(evt)
    }

    const countTrayek = (val: any) => {
      if (val < 1) {
        isAnyTrayek.value = false
      }
    }

    const applyFilter = (evt: any) => {
      isSearch.value = true
      isAnyTrayek.value = true
      let tolfilter = null
      if (isTolFilter.value !== null) {
        if (isTolFilter.value === 1) {
          tolfilter = true
        } else {
          tolfilter = false
        }
      }
      store.dispatch('flushTrayekHeadFilter')
      store.dispatch('setTrayekHeadSearchFilter', inputSearch.value)
      store.dispatch('setTrayekHeadIsTolFilter', tolfilter)
      store.dispatch('setTrayekHeadClassFilter', selectedClass.value ? selectedClass.value.Id : null)
      store.dispatch('setReloadTrayek', true)
      refOverlayAction.value.toggle(evt)
    }

    const resetFilter = () => {
      isTolFilter.value = null
      selectedClass.value = null
      store.dispatch('setTrayekHeadIsTolFilter', null)
      store.dispatch('setTrayekHeadClassFilter', null)
    }

    const getAllClass = () => {
      apiUseCase.get(`${endpointClass}${returnUrlPrams({
        search: inputSearchClass.value,
        // filterField: isEmpty(searchFields.value)
        filterField: ['Name']
      })}`).then((response) => {
        const temp = response.result.map((item: any) => ({
          ...item,
          label: item.Name,
          value: item.Id
        }))
        classOptions.value = temp
      })
    }

    const onSearchClass = (val: any) => {
      inputSearchClass.value = val
      getAllClass()
    }

    const showForm = () => {
      router.push({
        name: 'master-trayek-tourist-form'
      })
    }

    onMounted(() => {
      resetFilter()
      store.dispatch('flushTrayekHeadFilter')
      store.dispatch('setTrayekPath', path)
      getAllClass()
      isLoading.value = false
    })

    return {
      icon,
      inputSearch,
      onSearch,
      pageState,
      openOverlayPanel,
      refOverlayAction,
      classOptions,
      selectedClass,
      countTrayek,
      isSearch,
      isAnyTrayek,
      applyFilter,
      isTolFilter,
      showForm,
      path,
      resetFilter,
      onSearchClass,
      isLoading
    }
  }
})

