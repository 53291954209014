
import { defineComponent, getCurrentInstance, onMounted, ref, reactive } from 'vue'
import Button from 'primevue/button'
import Card from 'primevue/card'
import InputIconRight from '@/views/components/form/InputIconRight.vue'

const endpoint = '/management/v1/Trayek'

export default defineComponent({
  name: 'TrayekForm',
  components: {
    // Button,
    // Card
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $icon
    } = app!.appContext.config.globalProperties
    const icon = $icon

    const inputSearch = ref(null)
    const isEmpty = ref(true)

    const onSearch = () => {
      console.log('aaa')
    }

    onMounted(() => {
      console.log('asdad')
    })

    return {
      icon,
      inputSearch,
      onSearch,
      isEmpty
    }
  }
})

