
import {
  ref,
  onMounted,
  reactive,
  defineComponent,
  toRefs
} from 'vue'
import { useStore } from 'vuex'

import Button from 'primevue/button'
import apiUseCase from '@/usecase/apiUseCase'
import { Form, Field } from 'vee-validate'
import rgxExp from '@/utils/helpers/regExp'
import * as Yup from 'yup'

export default defineComponent({
  name: 'VehicleTable',
  components: {
    // InputText,
    Button,
    // Textarea,
    Form,
    Field,
  },
  props: {
    module: {
      type: String,
      default: ''
    }
  },
  emits: ['hideSidebar', 'onSubmit'],
  setup(props, { emit }) {
    const store = useStore()
    const {
      module
    } = toRefs(props)
    const isLoading = ref(true)
    const endpointVehicle = '/management/v1/MsVehicle'
    const vehicleId = ref(store.state.hjpTable.modal.data.Id)
    const dataVehicle = ref()
    // const description = ref('')
    const initialValue = reactive({
      Description: store.state.vehicleMutation.description ?? null
    })

    const schema = Yup.object().shape({
      Description: Yup.string()
        .matches(rgxExp.notPrefixSpace, 'Keterangan tidak boleh diawali dengan spasi.')
        // .matches(rgxExp.notSpaceInFirstChar, 'Keterangan tidak boleh diawali dengan spasi.')
        .required('Keterangan tidak boleh kosong.')
    })

    const getData = () => {
      store.dispatch('showLoading')
      isLoading.value = true
      apiUseCase.get(`${endpointVehicle}/${vehicleId.value}`)
        .then(async ({ result }) => {
          dataVehicle.value = result
          store.dispatch('hideLoading')
          isLoading.value = false
        })
        .catch(() => {
          store.dispatch('hideLoading')
          isLoading.value = false
        })
    }

    const hideSidebar = () => {
      emit('hideSidebar')
    }

    const nextPage = (val: any) => {
      // const dataForm = ref({
      //   nopol: dataVehicle.value.Nopol,
      //   currTrayek: dataVehicle.value.Trayek.Name,
      //   class: 'Ekonomi',
      //   pps: [],
      //   description: null
      // })
      store.dispatch('setFirstVehicleMutation', dataVehicle.value)
      store.dispatch('setDescriptionVehicleMutation', val.Description)
      store.dispatch('setVehicleMutationFormStep', 2)
      // emit('onSubmit', dataForm.value)
    }

    onMounted(() => {
      getData()
    })

    return {
      isLoading,
      hideSidebar,
      nextPage,
      // description,
      dataVehicle,
      initialValue,
      schema,
      module
    }
  }
})
