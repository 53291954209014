
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  defineAsyncComponent
} from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ProgressSpinner from 'primevue/progressspinner'
import apiUseCase from '@/usecase/apiUseCase'
import moment from 'moment'
import store from '@/store'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import HjpDefaultModal from '@/views/components/modal/ModalDefault.vue'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpAlertModal from '@/views/components/modal/HjpAlertModal.vue'
import EditSlotForm from '@/views/pages/master-setting/trayek/form/EditSlot.vue'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import OverlayPanel from 'primevue/overlaypanel'
import IResponse from '@/utils/interfaces/iResponse'
import Popper from 'vue3-popper'
import { isNull } from 'lodash'
import { useRoute } from 'vue-router'
import { ITrayek } from '@/utils/interfaces/iTrayek'

const VehicleMutationForm = defineAsyncComponent(() => import('@/views/pages/master-setting/vehicle/MutationForm.vue'))

const endpoint = '/management/v1/Trayek'
const endpointDetail = '/management/v1/TrayekDetail'
const TrayekTypeMainId = '8e072a19-599c-45f0-a337-08dbd378cce6'
const TrayekTypeReserveId = '5854668d-2806-41c2-c282-08dbd5e0716f'
const TrayekTypeTouristId = 'add9ce99-53e6-4068-c283-08dbd5e0716f'
const TrayekTypeAirportId = '971ab31f-207d-4ad5-8670-d9fcb9185475'

const moduleStore = 'hjpTable'

export default defineComponent({
  name: 'TrayekForm',
  components: {
    DataTable,
    Column,
    HjpCofirmModal,
    Paginator,
    ListActions,
    HjpDefaultModal,
    OverlayPanel,
    VehicleMutationForm,
    EditSlotForm,
    HjpAlertModal,
    HjpSideBar,
    ProgressSpinner,
    Popper
  },
  props: {
    trayekType: {
      type: String,
      default: () => ''
    },
    path: {
      type: String,
      default: () => ''
    }
  },
  emits: ['countTrayek'],
  setup(props, { emit }: any) {
    const route = useRoute()
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const { trayekType, path } = toRefs(props)
    const dataSource = ref<ITrayek[]>([])
    const arrTemp = ref([]) as any
    const objTemp = ref({}) as any
    const detailHeaderCount = ref(0)
    const showDetailId = ref(null) as any
    const TrayekTypeId = ref()
    const isRegular = ref(true)
    const isAirport = ref(false)
    const detailHeader = ref([]) as any
    const dataRow = ref(null) as any
    const datatableSource = ref([])
    const showConfirmDialog = ref(false)
    const isShowAlertDialog = ref(false)
    const totalTrayekRecords = ref(0)
    const totalRecords = ref(0)
    const refOverlayAction = ref()
    const isDeleteHeader = ref(true)
    const showModalForm = ref(false)
    const showDialogForm = computed({
      get: () => store.state[moduleStore].dialog.show,
      set: (value) => {
        store.dispatch('setShowDialog', value)
      }
    })
    const paginate = reactive({
      pageNumber: 1,
      pageSize: 10
    })
    // const path = ref(store.state.trayek.path)
    const tableHeader = ref([
      { title: 'trayek', class: 'col-span-3' },
      { title: 'kelas', class: 'col-span-2' },
      { title: 'rit', class: 'col-span-5' },
      { title: 'aksi', class: '' },
    ])
    const tableHeaderReserve = ref([
      { title: 'trayek', class: 'col-span-6' },
      { title: 'kelas', class: 'col-span-5' },
      { title: 'aksi', class: '' },
    ])
    const filters = ref({
      search: '',
      filterField: ['nameSearch', 'codeSearch'],
      pageNumber: 1,
      pageSize: 10,
      custom: [] as any
    })

    const confirmDialog = {
      title: 'Hapus Trayek?',
      subTitle: 'Data pada trayek yang dihapus tidak dapat dikembalikan, Tetap hapus trayek?',
      btnSubmitTitle: 'Hapus Trayek',
      imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
    }

    const alertDialog = {
      title: 'Gagal Menghapus Data',
      subTitle: 'Trayek yang Anda ingin hapus saat ini memiliki transaksi. Silakan tunggu transaksi selesai baru dapat hapus',
      btnSubmitTitle: 'Kembali',
      imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
    }

    const generateHeaderDetail = () => {
      detailHeader.value = []
      detailHeader.value.push(
        {
          field: 'nopol',
          headerName: 'Nopol',
          searchable: true,
          fieldSearch: 'nopol'
        }
      )
      if (!isRegular.value && !isAirport.value) {
        detailHeader.value.push(
          {
            field: 'hullNumber',
            headerName: 'No. Lambung',
            searchable: true,
            fieldSearch: 'hullNumber'
          },
          {
            field: 'purchasedAt',
            headerName: 'Tahun Pembuatan',
            searchable: true,
            fieldSearch: 'purchasedAt'
          },
          {
            field: 'type',
            headerName: 'Tipe / Karoseri',
            searchable: true,
            fieldSearch: 'type'
          }
        )
      }
      console.log('generateHeadDetail', detailHeaderCount.value)
      for (let i = 1; i <= detailHeaderCount.value; i++) {
        detailHeader.value.push(
          {
            field: `pp${i}`,
            headerName: `PP${i}`,
            searchable: true,
            fieldSearch: `pp${i}`
          }
        )
      }
      console.log('detailHeader', detailHeader.value)
    }

    const formatRit = (data: any) => {
      let temp = ''
      // console.log('data', data)
      // const data = ['rit1', 'rit2', 'rit3']
      data.forEach((x: any, index: any) => {
        if (index === 0) {
          temp = x.MsRoute.Name
        } else if (index % 2 === 0) {
          temp = `${temp} / ${x.MsRoute.Name}`
        } else if (index % 2 !== 0) {
          temp = `${temp} <i class="text-sm font-normal text-grey pi pi-arrow-right"></i> ${x.MsRoute.Name}`
          // console.log('temp', temp)
        }
      })
      temp = temp === '' ? '-' : temp
      // console.log('temp', temp)

      return temp
    }

    const setDataRowOnState = () => {
      // console.log('dataRow', dataRow.value)
      store.dispatch('storeRowDataTable', dataRow.value)
    }

    const getData = async () => {
      const { result, error, count } = await apiUseCase.get(`${endpoint}${returnUrlPrams(filters.value)}`)
      if (!error) {
        totalTrayekRecords.value = count
        const resultMap = result as ITrayek[]
        dataSource.value = resultMap
        emit('countTrayek', dataSource.value.length)
      }
    }

    const mappingDetail = (dataX: any) => {
      console.log('mappingdetail', dataX)
      let count = 0
      // eslint-disable-next-line array-callback-return
      dataX.map((data: any) => {
        console.log('TrayekSubs', data.TrayekSubs.length)
        count = count < data.TrayekSubs.length ? data.TrayekSubs.length : count
      })
      detailHeaderCount.value = count
      generateHeaderDetail()
      arrTemp.value = []
      // eslint-disable-next-line array-callback-return
      dataX.map((data: any, index: any) => {
        objTemp.value = {
          ...data,
          Id: data.MsVehicle ? data.MsVehicle.Id : null,
          DetailId: data.Id,
          TrayekId: data.Trayek.Id
        }
        objTemp.value.nopol = data.RegNumber ?? '-'
        objTemp.value.hullNumber = data.HullNumber
        objTemp.value.purchasedAt = data.PurchasedAt
        objTemp.value.type = data.Type
        data.TrayekSubs.forEach((x: any, i: any) => {
          objTemp.value[`pp${i + 1}`] = x.DepartureTime
          if (x.TrayekSubsDetails.length > 0) {
            const TrayekSubsDetailsTemp = x.TrayekSubsDetails
            console.log('groupSubsDetail', x.TrayekSubsDetails)
            const groupSubsDetailTemp = TrayekSubsDetailsTemp.reduce((group: any, item: any) => {
              const { RitOrder } = item
              group[RitOrder - 1] = group[RitOrder - 1] ?? []
              group[RitOrder - 1].push(item)
              return group
            }, {})
            const groupSubsDetail = Object.keys(groupSubsDetailTemp).map((key: any) => groupSubsDetailTemp[key])
            x.groupTrayekSubsDetails = groupSubsDetail
          }
        })
        arrTemp.value.push(objTemp.value)
      })
      datatableSource.value = arrTemp.value
      console.log('datatableSource', datatableSource.value)
    }

    const getDataDetail = (val: any) => {
      store.dispatch('showLoading')
      // const params = `?trayekId=${val}`
      apiUseCase.get(`${endpointDetail}${returnUrlPrams({
        search: val,
        filterField: ['trayekId'],
        pageNumber: paginate.pageNumber,
        pageSize: paginate.pageSize
      })}`).then((response) => {
        console.log('responseX', response)
        totalRecords.value = response.count
        detailHeaderCount.value = 0
        const resTemp = response.result.map((data: any) => ({
          ...data,
          HullNumber: data.MsVehicle?.HullNumber,
          PurchasedAt: data.MsVehicle?.PurchasedAt,
          Type: data.MsVehicle?.Type
        }))
        mappingDetail(resTemp)
      })
      store.dispatch('hideLoading')
    }

    const showDetailPage = (id: any) => {
      datatableSource.value = []
      if (showDetailId.value !== id) {
        showDetailId.value = id
        getDataDetail(id)
      } else {
        showDetailId.value = null
      }
      // console.log('datatableSource', datatableSource.value)
    }

    const flushFilters = () => {
      filters.value.pageNumber = 1
      filters.value.search = ''
      filters.value.custom = []
    }

    const initFilters = () => {
      console.log('reloadX', store.state.trayek.filters)
      flushFilters()
      if (trayekType.value.toLowerCase() === 'reguler') {
        TrayekTypeId.value = TrayekTypeMainId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeMainId])
        isRegular.value = true
        isAirport.value = false
      } else if (trayekType.value.toLowerCase() === 'cadangan') {
        TrayekTypeId.value = TrayekTypeReserveId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeReserveId])
        isRegular.value = false
        isAirport.value = false
      } else if (trayekType.value.toLowerCase() === 'pariwisata') {
        TrayekTypeId.value = TrayekTypeTouristId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeTouristId])
        isRegular.value = false
        isAirport.value = false
      } else if (trayekType.value.toLowerCase() === 'bandara') {
        TrayekTypeId.value = TrayekTypeAirportId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeAirportId])
        isRegular.value = false
        isAirport.value = true
      }
      filters.value.search = store.state.trayek.filters.search ?? ''
      if (store.state.trayek.filters.isTol === true && !isNull(store.state.trayek.filters.isTol)) {
        filters.value.custom.push(['isTol', '=', 'true'])
      } else if (store.state.trayek.filters.isTol === false && !isNull(store.state.trayek.filters.isTol)) {
        filters.value.custom.push(['isTol', '=', 'false'])
      }

      if (store.state.trayek.filters.classId) {
        filters.value.custom.push(['classId', '=', store.state.trayek.filters.classId])
      }
      console.log('filters', filters.value)
    }

    const onDeleteHeader = (val: any) => {
      store.dispatch('showLoading')
      dataRow.value = val
      confirmDialog.title = 'Hapus Trayek?'
      confirmDialog.subTitle = 'Data pada trayek yang dihapus tidak dapat dikembalikan, Tetap hapus trayek?'
      confirmDialog.btnSubmitTitle = 'Hapus Trayek'
      confirmDialog.imgContent = require('@/assets/img/modal-confirmation/delete-default.svg')
      isDeleteHeader.value = true
      showConfirmDialog.value = true
      store.dispatch('hideLoading')
    }

    const hideDialogConfirmation = () => {
      console.log('hideDialog')
      showConfirmDialog.value = false
    }

    const submitFromDialog = () => {
      store.dispatch('showLoading')
      console.log('submitDialog', dataRow.value)
      const deletedId = isDeleteHeader.value ? dataRow.value.Id : dataRow.value.DetailId
      apiUseCase.delete(`${isDeleteHeader.value ? endpoint : endpointDetail}`, deletedId).then((res: IResponse) => {
        console.log('res', res)
        if (!res.error) {
          $toast.add({
            severity: 'success',
            detail: 'Data berhasil dihapus',
            group: 'bc',
            closable: false,
            life: 3000
          })
          getData()
        } else {
          console.log('isDeleteHeader', isDeleteHeader.value)
          // eslint-disable-next-line no-lonely-if
          if (!isDeleteHeader.value) {
            // eslint-disable-next-line no-use-before-define
            showAlertDialog()
          } else {
            $toast.add({
              severity: 'error',
              detail: res.message,
              group: 'bc',
              closable: false,
              life: 3000
            })
          }
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const onChangePage = (evt: any) => {
      console.log('onChangePage', evt)
      // paginate.pageNumber = (evt.page + 1) * paginate.top
      filters.value.pageNumber = evt.page + 1
      // console.log('obj pagin', paginate)
      getData()
      // const container = $('.wrap-hjp-data-list')
      // $(window).scrollTop(container.offset().top)
    }

    const onChangePageDetail = (evt: any) => {
      console.log('onChangePage', evt)
      // paginate.pageNumber = (evt.page + 1) * paginate.top
      paginate.pageNumber = evt.page + 1
      // console.log('obj pagin', paginate)
      getDataDetail(showDetailId.value)
      // const container = $('.wrap-hjp-data-list')
      // $(window).scrollTop(container.offset().top)
    }

    const showDropDown = (event: any, item: any) => {
      dataRow.value = item
      console.log('dataRow', [event, dataRow.value])
      setDataRowOnState()
      refOverlayAction.value.toggle(event)
    }

    const hideOverlay = () => {
      refOverlayAction.value.hide()
    }

    const onChange = () => {
      showDialogForm.value = true
    }

    const onEdit = () => {
      console.log('onEdit')
      showModalForm.value = true
    }

    const hideModalForm = (state: any = null) => {
      console.log('xxxxx')
      if (state === 0) {
        getDataDetail(showDetailId.value)
      } else if (state === 1) {
        alertDialog.title = 'Gagal Mengubah Data'
        alertDialog.subTitle = 'Trayek yang Anda ingin ubah saat ini memiliki transaksi. Silakan tunggu transaksi selesai baru dapat diubah'
        isShowAlertDialog.value = true
      }
      showModalForm.value = false
    }

    const onDelete = () => {
      console.log('onDelete')
      confirmDialog.title = 'Hapus Slot Trayek?'
      confirmDialog.subTitle = 'Data pada slot trayek yang dihapus tidak dapat dikembalikan, Tetap hapus slot trayek?'
      confirmDialog.btnSubmitTitle = 'Hapus Slot Trayek'
      confirmDialog.imgContent = require('@/assets/img/modal-confirmation/delete-default.svg')
      isDeleteHeader.value = false
      showConfirmDialog.value = true
    }

    const hideDialogForm = () => {
      showDialogForm.value = false
    }

    const showAlertDialog = () => {
      isShowAlertDialog.value = true
    }

    const hideAlertDialog = () => {
      isShowAlertDialog.value = false
    }

    watch(() => store.state.trayek.isReload, (val: any) => {
      console.log('reload')
      if (val) {
        // filters.value = store.getters.getTrayekHeadFilters
        initFilters()
        getData()
        showDetailId.value = null
      }
    })

    onMounted(() => {
      flushFilters()
      console.log('trayekType', trayekType.value)
      if (trayekType.value.toLowerCase() === 'reguler') {
        TrayekTypeId.value = TrayekTypeMainId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeMainId])
        isRegular.value = true
        isAirport.value = false
      } else if (trayekType.value.toLowerCase() === 'cadangan') {
        TrayekTypeId.value = TrayekTypeReserveId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeReserveId])
        isRegular.value = false
        isAirport.value = false
      } else if (trayekType.value.toLowerCase() === 'pariwisata') {
        TrayekTypeId.value = TrayekTypeTouristId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeTouristId])
        isRegular.value = false
        isAirport.value = false
      } else if (trayekType.value.toLowerCase() === 'bandara') {
        TrayekTypeId.value = TrayekTypeAirportId
        filters.value.custom.push(['trayekTypeId', '=', TrayekTypeAirportId])
        isRegular.value = false
        isAirport.value = true
      }
      filters.value.search = store.state.trayek.filters.search ?? ''
      if (store.state.trayek.filters.isTol === true && !isNull(store.state.trayek.filters.isTol)) {
        filters.value.custom.push(['isTol', '=', 'true'])
      } else if (store.state.trayek.filters.isTol === false && !isNull(store.state.trayek.filters.isTol)) {
        filters.value.custom.push(['isTol', '=', 'false'])
      }

      if (store.state.trayek.filters.classId) {
        filters.value.custom.push(['classId', '=', store.state.trayek.filters.classId])
      }
      // countPp()
      getData()
      // getDataDetail()
      // formatRit()
    })

    return {
      tableHeader,
      tableHeaderReserve,
      dataSource,
      formatRit,
      datatableSource,
      moment,
      detailHeader,
      showDetailId,
      showDetailPage,
      confirmDialog,
      showConfirmDialog,
      hideDialogConfirmation,
      onDeleteHeader,
      submitFromDialog,
      path,
      isRegular,
      isAirport,
      paginate,
      onChangePage,
      totalRecords,
      refOverlayAction,
      hideOverlay,
      showDropDown,
      showDialogForm,
      onChange,
      hideDialogForm,
      onEdit,
      onDelete,
      showAlertDialog,
      isShowAlertDialog,
      alertDialog,
      hideAlertDialog,
      showModalForm,
      hideModalForm,
      dataRow,
      filters,
      totalTrayekRecords,
      onChangePageDetail
    }
  }
})
