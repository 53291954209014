
import { ref, onMounted, reactive, defineComponent } from 'vue'
import DataTable from '@/views/components/table/HjpDataTable.vue'
import VehicleForm from '@/views/pages/master-setting/vehicle/Form.vue'
import VehicleMutationForm from '@/views/pages/master-setting/vehicle/MutationForm.vue'

const slotRit = 'Trayek.TrayekRoute'

export default defineComponent({
  name: 'VehicleTable',
  components: {
    DataTable,
    VehicleForm,
    VehicleMutationForm
  },
  setup() {
    const endpoint = '/management/v1/MsVehicle'
    const tabelParams = reactive({
      endpoint: '/management/v1/MsVehicle',
      endpointSuffix: '',
      headerTitle: 'Master Kendaraan',
      buttonTitle: 'Tambah Kendaraan',
      showColumnActivate: true,
      showAction: true,
      isChange: true,
      canDelete: false,
      editTitle: 'Edit Kendaraan',
      changeTitle: 'Mutasi Kendaraan',
      delTitle: 'Hapus Kendaraan',
      confirmDialogDelete: {
        title: 'Hapus Kendaraan?',
        subTitle: 'Menghapus Kendaraan akan berdampak kepada kondektur dan trayek bus yang sudah diassign, Tetap hapus kendaraan?',
        btnSubmitTitle: 'Hapus Kendaraan',
      },
      columns: [
        {
          field: 'HullNumber',
          headerName: 'No. Lambung',
          searchable: false,
          fieldSearch: 'hullnumber'
        },
        {
          field: 'Nopol',
          headerName: 'No Polisi',
          searchable: true,
          fieldSearch: 'nopol'
        },
        {
          field: 'Type',
          headerName: 'Type / Karoseri Bus',
          fieldSearch: 'Type'
        },
        {
          field: 'Trayek.Name',
          headerName: 'Trayek',
          searchable: false,
          fieldSearch: 'Trayek.Name'
        },
        {
          field: slotRit,
          headerName: 'RIT',
          isRender: true,
        },
        {
          field: 'SeatCapacity',
          headerName: 'Kapasitas',
          searchable: false
        },
      ]
    })

    const formatRit = (data: any) => {
      let temp = ''
      data.forEach((x: any, index: any) => {
        if (index === 0) {
          temp = x.MsRoute.Name
        } else if (index % 2 === 0) {
          temp = `${temp} / ${x.MsRoute.Name}`
        } else if (index % 2 !== 0) {
          temp = `${temp} <i class="text-xs font-normal pi pi-arrow-right"></i> ${x.MsRoute.Name}`
          console.log('temp', temp)
        }
      })
      temp = temp === '' ? '-' : temp
      console.log('temp', temp)

      return temp
    }

    return {
      tabelParams,
      slotRit,
      formatRit
    }
  }
})
