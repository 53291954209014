import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VehicleForm = _resolveComponent("VehicleForm")!
  const _component_VehicleMutationForm = _resolveComponent("VehicleMutationForm")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_DataTable, _normalizeProps(_guardReactiveProps(_ctx.tabelParams)), {
      [_ctx.slotRit]: _withCtx(({ slotProps: { data } }) => [
        (data.TrayekRoute.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, "-"))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", {
                innerHTML: _ctx.formatRit(data.TrayekRoute)
              }, null, 8, _hoisted_3)
            ]))
      ]),
      form: _withCtx(() => [
        _createVNode(_component_VehicleForm)
      ]),
      secondform: _withCtx(() => [
        _createVNode(_component_VehicleMutationForm)
      ]),
      customDialog: _withCtx(() => [
        _createVNode(_component_VehicleMutationForm)
      ]),
      _: 2
    }, 1040)
  ]))
}